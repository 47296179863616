import React from 'react';
import { lightBlue } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faMicroscope } from '@fortawesome/free-solid-svg-icons';
import useBreakpoint from '../../hooks/useBreakpoint';

export default function Treatments() {
  const { breakpoint } = useBreakpoint();
  const mobile = breakpoint === 'sm' || breakpoint === 'xs';
  return (
    <div
      style={{
        display: !mobile && 'flex',
        justifyContent: mobile ? 'center' : 'space-around',
        padding: '35px 0',
        width: '100%',
      }}
    >
      <div>
        <div
          style={{
            display: 'flex',
            marginLeft: '20px',
            alignItems: 'center',
            padding: mobile ? '20px' : '',
            color: lightBlue,
          }}
        >
          <FontAwesomeIcon
            icon={faEye}
            style={{ fontSize: '2em', marginRight: '15px' }}
          />
          <h2 style={{ marginBottom: '17px' }}>Our Treatment Capabilities:</h2>
        </div>
        <ul
          style={{
            marginTop: 0,
            marginRight: 0,
            width: mobile ? '85%' : '90%',
          }}
        >
          <li>
            Comprehensive eye exams including Schmirer tear testing, tonometry
            (test for glaucoma), fluorescein staining, slit lamp examination
            (magnified and systematic eye exam), and ophthalmoscopy (detailed
            retinal examination)
          </li>
          <li>
            Examination for OFA Eye Registry to evaluate breeding animals for
            inherited eye disease
          </li>
          <li>Cataract surgery with lens replacement</li>
          <li>Conjunctival grafts</li>
          <li>Corneal surgery</li>
          <li>Surgical and medical management of non-healing corneal ulcers</li>
          <li>Distichia/ectopic cilia corrections</li>
          <li>Oculoplastic and reconstructive eyelid surgery</li>
          <li>Glaucoma management</li>
          <li>Medical and surgical management of KCS (dry eye)</li>
          <li>Ocular prosthesis</li>
        </ul>
      </div>
      <div style={{ width: !mobile ? '150%' : '' }}>
        <div
          style={{
            display: 'flex',
            marginLeft: '20px',
            alignItems: 'center',
            padding: mobile ? '20px' : '',
            color: lightBlue,
          }}
        >
          <FontAwesomeIcon
            icon={faMicroscope}
            style={{ fontSize: '2em', marginRight: '15px' }}
          />
          <h2 style={{ marginBottom: '17px' }}>
            Our Advanced Treatment Capabilities:
          </h2>
        </div>
        <ul style={{ marginTop: 0, width: mobile ? '85%' : '90%' }}>
          <li>
            Electroretinogram (ERG) which measures electrical responses in the
            retina and aids in diagnosing various retinal diseases
          </li>
          <li>Phacoemulsification equipment for performing cataract surgery</li>
          <li>
            Cryosurgical unit for minimally invasive treatment of eyelid masses,
            distichia, trichiasis and ectopic cilia
          </li>
          <li>
            Diode laser for the treatment of glaucoma, some ocular tumors and
            retinal diseases
          </li>
          <li>
            Operating microscopes and ophthalmic surgical instruments to perform
            delicate intraocular and corneal surgery
          </li>
          <li>
            Ocular ultrasound equipment to perform more precise intraocular and
            orbital ultrasound evaluations
          </li>
          <li>Gonioscopy to determine genetic predisposition for glaucoma</li>
          <li>
            Chromatic pupillometry to aid in differentiating retinal diseases
            from neurologic conditions
          </li>
        </ul>
      </div>
    </div>
  );
}
