import React from 'react';
import { flexColumn, darkBlue, lightBlue, white } from '../styles';
import useBreakpoint from '../../hooks/useBreakpoint';

export default function PSA() {
  const { breakpoint, windowSize } = useBreakpoint();
  const mobile =
    breakpoint === 'md' || breakpoint === 'sm' || breakpoint === 'xs';
  return (
    <div
      style={{
        display: 'flex',
        height: mobile
          ? breakpoint === 'xs'
            ? ''
            : 297
          : windowSize.width < 1644
          ? 471
          : 410,
        flexDirection: breakpoint === 'xs' ? 'column' : '',
      }}
    >
      {(windowSize.width > 871 || windowSize.width < 600) && (
        <img
          src="./images/bunnyEye.jpg"
          style={{
            maxHeight: breakpoint !== 'xs' ? '100%' : '',
            height: breakpoint === 'xs' ? '350px' : '',
            maxWidth: '100%',
            objectFit: 'cover',
            objectPosition: '0px 91%',
          }}
        />
      )}
      <div style={{ maxWidth: '100%', height: '100%' }}>
        <div style={{ backgroundColor: darkBlue, padding: '20px 40px' }}>
          <h1
            style={{ color: white, lineHeight: 1, fontSize: mobile ? 24 : 38 }}
          >
            When Does My Pet Need an Eye Evaluation?
          </h1>
          <h3
            style={{
              color: lightBlue,
              lineHeight: mobile ? (breakpoint === 'xs' ? 1.4 : 1) : 0,
              fontSize: '1em',
            }}
          >
            The following are common indications:
          </h3>
        </div>
        <div style={{ backgroundColor: '#ebe8e8', padding: mobile ? 17 : 30 }}>
          <ul
            style={{
              margin: 0,
              fontSize: mobile ? 15 : 20,
              color: lightBlue,
              fontWeight: 500,
            }}
          >
            <li>Eyes appear red</li>
            <li>Eyes appear cloudy</li>
            <li>Increased discharge and tearing</li>
            <li>Squinting or rubbing</li>
            <li>Decreased vision</li>
          </ul>
          <p
            style={{
              fontWeight: 500,
              margin: mobile ? '6px 0 0 0' : '',
              fontSize: mobile && '0.7em',
            }}
          >
            A referral from a primary care veterinarian is requested when seeing
            our veterinary ophthalmologist.
          </p>
        </div>
      </div>
    </div>
  );
}
