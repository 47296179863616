import React from 'react';
import useBreakpoint from '../../hooks/useBreakpoint';

export default function ContactHero() {
  const { breakpoint } = useBreakpoint();
  const mobile = breakpoint === 'sm' || breakpoint === 'xs';
  return (
    <div
      style={{
        width: '100%',
        height: mobile ? (breakpoint === 'xs' ? 150 : 275) : 350,
        backgroundImage: "url('./images/iguanaEye.jpg')",
        backgroundPosition: mobile ? '50% 15%' : '0% 20%',
        backgroundSize: mobile ? '150%' : '100%',
        justifyContent: 'flex-end',
      }}
    />
  );
}
