import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { gray, lightBlue } from './styles';
import useBreakpoint from '../hooks/useBreakpoint';

const Navbar = ({ handleClick, isLoggedIn }) => {
  const location = useLocation();
  const { breakpoint } = useBreakpoint();
  const mobile = breakpoint === 'sm' || breakpoint === 'xs';
  const linkStyle = {
    fontSize: mobile ? '1.2em' : '1.5em',
    fontWeight: 500,
    transition: 'transform 0.3s ease',
  };
  const isLocation = (pathname) => {
    return location.pathname === pathname;
  };
  const [hovered, setHovered] = useState('');

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: breakpoint === 'xs' && 'column',
        // animation: '1s ease 0s 1 fadeIn',
      }}
    >
      <Link to={'/home'}>
        <img
          src="./images/CAECLogoDark.svg"
          alt="Calgary Animal Eyecare Logo"
          style={{
            width: 200,
            margin: mobile ? '0 auto 5px auto' : '0 50px 5px 70px',
          }}
        />
      </Link>
      <nav style={{ marginBottom: breakpoint === 'xs' ? '2em' : '' }}>
        <Link
          style={{
            color: isLocation('/home') ? lightBlue : gray,
            ...linkStyle,
          }}
          to="/home"
          onMouseEnter={() => setHovered('about')}
          onMouseLeave={() => setHovered('')}
          className={hovered === 'about' ? 'hvr-grow' : ''}
        >
          About
        </Link>
        <Link
          style={{
            color: isLocation('/services') ? lightBlue : gray,
            ...linkStyle,
          }}
          to="/services"
          onMouseEnter={() => setHovered('services')}
          onMouseLeave={() => setHovered('')}
          className={hovered === 'services' ? 'hvr-grow' : ''}
        >
          Services
        </Link>
        <Link
          style={{
            color: isLocation('/contact') ? lightBlue : gray,
            ...linkStyle,
          }}
          to="/contact"
          onMouseEnter={() => setHovered('contact')}
          onMouseLeave={() => setHovered('')}
          className={hovered === 'contact' ? 'hvr-grow' : ''}
        >
          Contact
        </Link>
        <Link
          style={{
            color: isLocation('/referral') ? lightBlue : gray,
            ...linkStyle,
          }}
          to="/referral"
          onMouseEnter={() => setHovered('referral')}
          onMouseLeave={() => setHovered('')}
          className={hovered === 'referral' ? 'hvr-grow' : ''}
        >
          Referral
        </Link>
      </nav>
    </div>
  );
};

export default Navbar;
