import React from 'react';
import { connect } from 'react-redux';
import HomeHero from './HomeHero';
import Bio from './Bio';
import useBreakpoint from '../../hooks/useBreakpoint';

export const Home = (props) => {
  // const {username} = props
  const { breakpoint } = useBreakpoint();
  return (
    <div>
      <HomeHero />
      <div
        style={{
          textAlign: 'center',
          width: '95vw',
          margin: '50px auto 50px auto',
        }}
      >
        <h2 style={{ fontWeight: 400 }}>
          Our veterinary ophthalmologist specializes in vision care and the
          diagnosis and treatment of eye and eyelid disorders. Our ophthalmology
          service is dedicated to the diagnosis and treatment of ocular diseases
          in all animals including dogs, cats, horses, birds, rabbits, and
          exotic species.
        </h2>
      </div>
      <hr style={{ width: '95vw' }} />
      <Bio />
    </div>
  );
};

export default Home;
