import { useState, useEffect } from 'react';
import axios from 'axios';

const useForm = (callback, validate) => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    message: '',
    doctorName: '',
    hospitalName: '',
    vetPhone: '',
    vetFax: '',
    vetEmail: '',
    ownerName: '',
    ownerPhone: '',
    ownerAlternatePhone: '',
    ownerEmail: '',
    petName: '',
    petDOB: '',
    petSpecies: '',
    petBreed: '',
    petColor: '',
    petGender: '',
    healthConditions: '',
    presentingComplaint: '',
    petMedications: '',
    referralStatus: '',
  });

  const [files, setFiles] = useState(new FormData());

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const allowedFiles = ['.doc', '.docx', '.pdf', '.jpg', '.png'];

    const regex = new RegExp(
      '([a-zA-Z0-9s_\\.-:])+(' + allowedFiles.join('|') + ')$'
    );

    const { name, value } = e.target;
    if (name === 'files') {
      const inputFiles = e.target.files;
      const data = new FormData();

      for (let value of files.values()) {
        data.append('file', value);
      }

      for (let key in inputFiles) {
        if (inputFiles.hasOwnProperty(key)) {
          if (!regex.test(inputFiles[key].name.toLowerCase())) {
            throw 'wrong file type';
          }
          if (inputFiles[key].size > 10000000) {
            throw 'file is too large';
          } else {
            data.append('file', inputFiles[key]);
          }
        }
      }

      setFiles(data);

      axios.post('/api/upload', data);
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // todo: validate files
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors]);

  return { handleChange, handleSubmit, values, files, errors };
};

export default useForm;

// function logFormData(formData) {
//   for (let value of formData.values()) {
//     console.log(value);
//   }
// }
