import React from 'react';
import ContactHero from './ContactHero';
import ContactForm from './ContactForm';
import ContactList from './ContactList';
import { darkBlue } from '../styles';
import useBreakpoint from '../../hooks/useBreakpoint';

const Contact = () => {
  const { breakpoint } = useBreakpoint();
  const mobile = breakpoint === 'sm' || breakpoint === 'xs';
  return (
    <div style={{ textAlign: mobile && 'center' }}>
      <ContactHero />
      <h1
        style={{
          color: darkBlue,
          marginLeft: !mobile && 80,
          marginTop: 75,
          fontSize: '2.75em',
          lineHeight: 1,
        }}
      >
        Please contact us to book an appointment
      </h1>
      <div
        style={{
          display: 'flex',
          flexDirection: mobile && 'column',
        }}
      >
        <ContactForm />
        <ContactList />
      </div>
    </div>
  );
};

export default Contact;
