export function validateContact(values) {
  let errors = {};

  if (!values.name.trim()) {
    errors.name = 'Name required';
  }

  if (!values.email) {
    errors.email = 'Email required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.message) {
    errors.message = 'Message is required';
  }
  return errors;
}

export function validateReferral(values) {
  let errors = {};

  if (!values.doctorName.trim()) {
    errors.doctorName = 'Name required';
  }

  if (!values.vetEmail) {
    errors.vetEmail = 'Email required';
  } else if (!/\S+@\S+\.\S+/.test(values.vetEmail)) {
    errors.vetEmail = 'Email address is invalid';
  }

  return errors;
}
