import React, { useState } from 'react';
import { darkBlue, lightBlue, gray, error } from '../styles';
import { TextField } from '@mui/material';

const OwnerForm = ({
  values,
  handleChange,
  isSubmitted,
  breakpoint,
  errors,
  mobile,
}) => {
  return (
    <div
      style={{
        padding:
          breakpoint === 'xs'
            ? '10px 20px 0 20px'
            : breakpoint === 'md'
            ? '10px 35px 0 50px'
            : '10px 20px 0 40px',
      }}
    >
      <div style={{ width: '47vw', marginBottom: 30 }}>
        {errors.name && <p style={error}>{errors.name}</p>}
        <div
          style={{
            alignItems: mobile ? 'flex-start' : 'center',
            marginBottom: 15,
            flexDirection: mobile && 'column',
          }}
        >
          <h2
            style={{
              color: lightBlue,
              marginRight: mobile ? 10 : 72,
              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Owner Name
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.ownerName}
            type="text"
            name="ownerName"
            onChange={handleChange}
            variant="outlined"
            style={{ width: breakpoint === 'xs' ? 300 : 500 }}
            inputStyle={{ width: breakpoint === 'xs' ? 300 : 500 }}
          />
        </div>
        {errors.email && <p style={error}>{errors.email}</p>}
        <div
          style={{
            marginBottom: 15,
          }}
        >
          <h2
            style={{
              color: lightBlue,
              marginRight: mobile ? 15 : 77,
              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Phone
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.ownerPhone}
            type="text"
            name="ownerPhone"
            onChange={handleChange}
            variant="outlined"
            style={{ width: breakpoint === 'xs' ? 300 : 500 }}
            inputStyle={{ width: breakpoint === 'xs' ? 300 : 500 }}
          />
        </div>
        {errors.message && <p style={error}>{errors.message}</p>}
        <div
          style={{
            marginBottom: 15,
          }}
        >
          <h2
            style={{
              color: lightBlue,
              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Alternate Phone
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.ownerAlternatePhone}
            type="text"
            name="ownerAlternatePhone"
            onChange={handleChange}
            variant="outlined"
            style={{ width: breakpoint === 'xs' ? 300 : 500 }}
            inputStyle={{ width: breakpoint === 'xs' ? 300 : 500 }}
          />
        </div>
        {errors.message && <p style={error}>{errors.message}</p>}
        <div
          style={{
            marginBottom: 15,
          }}
        >
          <h2
            style={{
              color: lightBlue,
              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Email Address
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.ownerEmail}
            type="text"
            name="ownerEmail"
            onChange={handleChange}
            variant="outlined"
            style={{ width: breakpoint === 'xs' ? 300 : 500 }}
            inputStyle={{ width: breakpoint === 'xs' ? 300 : 500 }}
          />
        </div>
      </div>
    </div>
  );
};

export default OwnerForm;
