import React, { Fragment } from 'react';
import { white, darkBlue, lightBlue } from '../styles';
import useBreakpoint from '../../hooks/useBreakpoint';

const Bio = () => {
  const { breakpoint } = useBreakpoint();

  const mobile = breakpoint === 'sm' || breakpoint === 'xs';

  return (
    <div
      style={{
        display: 'flex',
        width: '90vw',
        margin: '0 auto 40px auto',
        flexDirection: (mobile || breakpoint === 'md') && 'column',
        alignItems: (mobile || breakpoint === 'md') && 'center',
      }}
    >
      <div style={{ padding: '50px 35px 0 0' }}>
        <img
          src="./images/kelliAndHarriet.jpeg"
          style={{
            maxWidth: 350,
          }}
        />
      </div>
      <div>
        <h1
          style={{
            color: darkBlue,
            fontSize: '2.75em',
            lineHeight: 1.5,
            fontWeight: 700,
          }}
        >
          Dr. Kelli Combs Ramey, DVM, DACVO
        </h1>
        <h2 style={{ color: lightBlue, fontWeight: 500, fontSize: '1.6em' }}>
          VETERINARY SPECIALIST, OPHTHALMOLOGY
        </h2>
        <p>
          Dr. Kelli Combs Ramey has practiced in Calgary since February of 2011
          providing a wide range of veterinary ophthalmology services. She is
          thrilled to bring this passion and experience to a stand-alone
          ophthalmology specialty clinic and continue to improve the lives of
          animals and their families. Her special interests include surgical
          management of ulcerative corneal diseases and cataract surgery.
          <br />
          <br />
          Dr. Ramey earned her Bachelor of Science degree in Biochemistry and
          Molecular Biology at Oklahoma State University (OSU), where she was
          accepted into early enrollment in the OSU Veterinary Medical College.
          Throughout undergraduate and veterinary school, she was a member of
          the OSU Varsity Women's Equestrian Team. In 2005, Dr. Ramey completed
          her doctorate in Veterinary Medicine from OSU and began a one-year
          rotating internship in small animal medicine and surgery at a critical
          care and multi-specialty referral hospital in the Kansas City
          metropolitan area. In 2006, Dr. Ramey joined the Eye Care for Animals
          team in Pasadena, California as a resident in Comparative Veterinary
          Ophthalmology. During her residency, she trained in all aspects of
          ophthalmology including surgical and medical management of ocular
          conditions in small animals, horses, rabbits, birds, and exotic
          species. Upon completion of her residency program at Eye Care for
          Animals in 2009, Dr. Ramey continued to practice in Pasadena and Palm
          Desert, California and served as a consultant for the Los Angeles Zoo
          prior to her move to Calgary.
        </p>
      </div>
    </div>
  );
};

export default Bio;
