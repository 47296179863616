import React, { useState, useRef } from 'react';
import OwnerForm from './OwnerForm';
import VetForm from './VetForm';
import PetForm from './PetForm';
import FileUploader from '../fileUploader/fileUploader';
import useForm from '../../hooks/useForm';
import Button from '../Button';
import { validateReferral } from '../../utils/validateInfo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useBreakpoint from '../../hooks/useBreakpoint';
import { gray } from '../styles';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import Preview from '../fileUploader/Preview';

const ReferralForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { handleChange, handleSubmit, values, files, errors } = useForm(
    submitForm,
    validateReferral
  );

  const recaptchaRef = useRef();

  const { breakpoint } = useBreakpoint();

  const validation = (statusCode) => {
    const messages = {
      200: 'Message Sent',
      403: 'Something went wrong',
    };
    return messages[statusCode];
  };

  // format file attachments is here because formData object
  // not passing to req.body because of body parsing middleware
  const formatFileAttachments = (formData) => {
    const attachments = [];
    for (let fileObj of formData.values()) {
      const attachmentObj = { filename: fileObj.name };
      attachments.push(attachmentObj);
    }
    return attachments;
  };

  const sendMessage = async () => {
    const recaptchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    const fileAttachments = formatFileAttachments(files);
    const response = await axios.post('/api/email/submitReferral', {
      values,
      fileAttachments,
      recaptchaToken,
    });
    const statusCode = response.request.status;
    if (statusCode === 200) {
      toast.success(validation(statusCode));
    } else {
      toast.warn(validation(statusCode));
    }
  };

  async function submitForm() {
    setIsSubmitted(true);
    sendMessage();
  }

  const mobile = breakpoint === 'sm' || breakpoint === 'xs';

  const formDataToArray = (formData) => {
    return [...formData.values()];
  };

  return (
    <div>
      <h2 style={{ margin: '30px 0 0 40px' }}>
        REFERRING VETERINARIAN INFORMATION
      </h2>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <VetForm
        values={values}
        handleChange={handleChange}
        isSubmitted={isSubmitted}
        breakpoint={breakpoint}
        errors={errors}
        mobile={mobile}
      />
      <h2 style={{ margin: '0 0 0 40px' }}>OWNER INFORMATION</h2>
      <OwnerForm
        values={values}
        handleChange={handleChange}
        isSubmitted={isSubmitted}
        breakpoint={breakpoint}
        errors={errors}
      />
      <h2 style={{ margin: '0 0 0 40px' }}>PET INFORMATION</h2>
      <PetForm
        values={values}
        handleChange={handleChange}
        isSubmitted={isSubmitted}
        breakpoint={breakpoint}
        errors={errors}
        mobile={mobile}
      />
      <h2 style={{ margin: '0 0 0 40px' }}>
        Please upload any documents or images related to this case:
      </h2>
      <div style={{ marginLeft: 20, marginBottom: 20 }}>
        <FileUploader
          values={values}
          handleChange={handleChange}
          isSubmitted={isSubmitted}
        />
        <Preview files={formDataToArray(files)} />
      </div>
      <ReCAPTCHA
        sitekey="6LezwzseAAAAABchf8ohtsMCp6TcW--oT5_xIqY9"
        size="invisible"
        ref={recaptchaRef}
      />
      <div style={{ marginLeft: 50, marginBottom: 50 }}>
        {!isSubmitted ? (
          <Button text="Submit" onClick={handleSubmit} />
        ) : (
          <Button text="Submitted" color={gray} />
        )}
      </div>
    </div>
  );
};

export default ReferralForm;
