import React, { Fragment } from 'react';
import { white, gray, darkBlue, lightBlue } from '../styles';
import useBreakpoint from '../../hooks/useBreakpoint';

const ReferralHero = () => {
  const { breakpoint } = useBreakpoint();

  const mobile = breakpoint === 'sm' || breakpoint === 'xs';

  const laptop =
    breakpoint === 'xl' || breakpoint === 'lg' || breakpoint === 'md';

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          backgroundColor: darkBlue,
          color: white,
          padding: !mobile
            ? laptop
              ? '40px 20px 40px 40px'
              : '40px 110px 40px 130px'
            : '20px 20px 50px 20px',
        }}
      >
        <h2
          style={{
            fontWeight: 500,
          }}
        >
          Thank you very much for your referral!
        </h2>
        <h2
          style={{
            marginBottom: 30,
            fontWeight: 500,
          }}
        >
          In order to provide the best care possible, we ask that all referrals
          are done online through our website. We will contact you once we have
          booked your client for an appointment. If you are having any
          difficulty with this or you need to source an alternative way to
          refer, please contact our office at 587-943-4620
        </h2>
      </div>
    </div>
  );
};

export default ReferralHero;
