import React from 'react';
import ServicesHero from './ServicesHero';
import Treatments from './Treatments';
import WhatToExpect from './WhatToExpect';
import PSA from './PSA';
import { flexCenterColumn } from '../styles';
import useBreakpoint from '../../hooks/useBreakpoint';

export default function Services() {
  const { breakpoint } = useBreakpoint();
  const desktop =
    breakpoint === 'xl' || breakpoint === 'xxl' || breakpoint === 'lg';
  return (
    <div>
      <ServicesHero />
      <div style={flexCenterColumn}>
        <div style={{ ...flexCenterColumn, width: desktop ? '78vw' : '' }}>
          <Treatments />
          <PSA />
          <WhatToExpect />
        </div>
      </div>
    </div>
  );
}
