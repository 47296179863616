import React, { useState } from 'react';
import { darkBlue, lightBlue, gray, error, flexBetween } from '../styles';
import { TextField, Select, MenuItem } from '@mui/material';

const PetForm = ({
  values,
  handleChange,
  isSubmitted,
  breakpoint,
  errors,
  mobile,
}) => {
  return (
    <div
      style={{
        padding:
          breakpoint === 'xs'
            ? '10px 20px 0 20px'
            : breakpoint === 'md'
            ? '10px 35px 0 50px'
            : '10px 20px 0 40px',
      }}
    >
      <div
        style={{
          marginBottom: 30,
          display: 'flex',
          flexWrap: 'wrap',
          maxWidth: 950,
        }}
      >
        {errors.name && <p style={error}>{errors.name}</p>}
        <div
          style={{
            marginBottom: 15,
            marginRight: 10,
          }}
        >
          <h2
            style={{
              color: lightBlue,
              marginRight: mobile ? 10 : 72,
              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Pet Name
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.petName}
            type="text"
            name="petName"
            onChange={handleChange}
            variant="outlined"
            style={{ width: 300 }}
            inputStyle={{ width: 300 }}
          />
        </div>
        {errors.email && <p style={error}>{errors.email}</p>}
        <div
          style={{
            marginBottom: 15,
            marginRight: 10,
          }}
        >
          <h2
            style={{
              color: lightBlue,
              marginRight: mobile ? 15 : 77,
              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            DOB
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.petDOB}
            type="text"
            name="petDOB"
            onChange={handleChange}
            variant="outlined"
            style={{ width: 300 }}
            inputStyle={{ width: 300 }}
          />
        </div>
        {errors.message && <p style={error}>{errors.message}</p>}
        <div
          style={{
            marginBottom: 15,
            marginRight: 10,
          }}
        >
          <h2
            style={{
              color: lightBlue,

              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Species
          </h2>
          <Select
            disabled={isSubmitted}
            value={values.petSpecies}
            name="petSpecies"
            onChange={handleChange}
            variant="outlined"
            style={{ width: 300 }}
            inputStyle={{ width: 300 }}
          >
            <MenuItem value={'Canine'}>Canine</MenuItem>
            <MenuItem value={'Feline'}>Feline</MenuItem>
            <MenuItem value={'Equine'}>Equine</MenuItem>
            <MenuItem value={'Other'}>Other</MenuItem>
          </Select>
        </div>
        {errors.message && <p style={error}>{errors.message}</p>}
        <div
          style={{
            marginBottom: 15,
            marginRight: 10,
          }}
        >
          <h2
            style={{
              color: lightBlue,

              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Breed
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.petBreed}
            type="text"
            name="petBreed"
            onChange={handleChange}
            variant="outlined"
            style={{ width: 300 }}
            inputStyle={{ width: 300 }}
          />
        </div>
        {errors.message && <p style={error}>{errors.message}</p>}
        <div
          style={{
            marginBottom: 15,
            marginRight: 10,
          }}
        >
          <h2
            style={{
              color: lightBlue,

              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Color
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.petColor}
            type="text"
            name="petColor"
            onChange={handleChange}
            style={{ width: 300 }}
            inputStyle={{ width: 300 }}
          />
        </div>
        {errors.message && <p style={error}>{errors.message}</p>}
        <div
          style={{
            marginBottom: 15,
            marginRight: 10,
          }}
        >
          <h2
            style={{
              color: lightBlue,

              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Gender
          </h2>
          <Select
            disabled={isSubmitted}
            value={values.petGender}
            name="petGender"
            onChange={handleChange}
            variant="outlined"
            style={{ width: 300 }}
            inputStyle={{ width: 300 }}
          >
            <MenuItem value={'Male (intact)'}>Male (intact)</MenuItem>
            <MenuItem value={'Male (neutered)'}>Male (neutered)</MenuItem>
            <MenuItem value={'Female (intact)'}>Female (intact)</MenuItem>
            <MenuItem value={'Female (spayed)'}>Female (spayed)</MenuItem>
            <MenuItem value={'Exotic'}>Unknown</MenuItem>
          </Select>
        </div>
      </div>
      <div>
        {errors.message && <p style={error}>{errors.message}</p>}
        <div
          style={{
            marginBottom: 15,
            marginRight: 10,
          }}
        >
          <h2
            style={{
              color: lightBlue,

              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Concurrent Health Conditions
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.healthConditions}
            type="text"
            name="healthConditions"
            onChange={handleChange}
            multiline
            rows={4}
            style={{ width: mobile ? 300 : 500 }}
            inputStyle={{ width: mobile ? 300 : 500 }}
          />
        </div>
        {errors.message && <p style={error}>{errors.message}</p>}
        <div
          style={{
            marginBottom: 15,
            marginRight: 10,
          }}
        >
          <h2
            style={{
              color: lightBlue,

              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Presenting Complaint
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.presentingComplaint}
            type="text"
            name="presentingComplaint"
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={4}
            style={{ width: mobile ? 300 : 500 }}
            inputStyle={{ width: mobile ? 300 : 500 }}
          />
        </div>
        {errors.message && <p style={error}>{errors.message}</p>}
        <div
          style={{
            marginBottom: 15,
            marginRight: 10,
          }}
        >
          <h2
            style={{
              color: lightBlue,
              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Current Medications (Ocular or Systemic)
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.petMedications}
            type="text"
            name="petMedications"
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={4}
            style={{ width: mobile ? 300 : 500 }}
            inputStyle={{ width: mobile ? 300 : 500 }}
          />
        </div>
        {errors.message && <p style={error}>{errors.message}</p>}
        <div
          style={{
            marginBottom: 15,
            marginRight: 10,
          }}
        >
          <h2
            style={{
              color: lightBlue,

              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Status of Referral
          </h2>
          <Select
            disabled={isSubmitted}
            value={values.referralStatus}
            name="referralStatus"
            onChange={handleChange}
            variant="outlined"
            style={{ width: mobile ? 300 : 500 }}
            inputStyle={{ width: mobile ? 300 : 500 }}
          >
            <MenuItem value={'First Available'}>First Available</MenuItem>
            <MenuItem value={'Priority'}>Priority</MenuItem>
            <MenuItem value={'Emergency'}>Emergency</MenuItem>
          </Select>
          {values.referralStatus === 'Emergency' && (
            <p style={{ color: 'red' }}>
              (If within business hours please call 587-943-4620 to discuss the
              case. If out of business hours please also email
              info@calgaryanimaleyecare.ca to alert us about the case)
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PetForm;
