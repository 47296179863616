export const white = '#fff';
export const gray = '#656565';
export const darkBlue = '#001b3e';
export const darkBlueTransparent = 'rgba(16, 29, 62, 0.85)';
export const lightBlue = '#006589';

export const fontSize = {
  L: '1.5em',
  XL: '2em',
};

export const flexBetween = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const flexText = {
  display: 'flex',
  alignItems: 'baseline',
};

export const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
};

export const flexCenterColumn = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const error = {
  color: 'red',
  margin: '0 0 10px 145px',
  lineHeight: 1,
};
