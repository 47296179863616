import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { white, gray, darkBlue, lightBlue } from '../styles';
import Button from '../Button';
import useBreakpoint from '../../hooks/useBreakpoint';

const HomeHero = () => {
  const { breakpoint } = useBreakpoint();

  const mobile = breakpoint === 'sm' || breakpoint === 'xs';

  const laptop =
    breakpoint === 'xl' || breakpoint === 'lg' || breakpoint === 'md';

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: mobile ? 800 : 530,
        flexDirection: mobile && 'column-reverse',
      }}
    >
      <div
        style={{
          backgroundColor: darkBlue,
          color: white,
          padding: !mobile
            ? laptop
              ? '40px 20px 40px 40px'
              : '40px 110px 40px 130px'
            : '20px 20px 50px 20px',
        }}
      >
        <h2>CALGARY ANIMAL EYE CARE</h2>
        <h1
          style={{
            fontSize: mobile || breakpoint === 'md' ? '2em' : '2.5em',
            lineHeight: 1.5,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          Calgary's first ophthalmology focused speciality veterinary centre
        </h1>
        <h2
          style={{
            marginBottom: 30,
            fontWeight: 500,
            maxWidth: 700,
          }}
        >
          We treat a variety of complex animal eye conditions. Locally owned and
          operated.
        </h2>
        <Link to="/contact">
          <Button text="contact us" center={mobile ? true : false} />
        </Link>
      </div>

      <div
        style={{
          backgroundImage: 'url(./images/catEye.jpg)',
          width: mobile ? '100vw' : '90vw',
          height: '100%',
          backgroundSize: breakpoint === 'md' ? '275%' : 'cover',
          backgroundPosition: !laptop
            ? 'right'
            : breakpoint === 'md'
            ? '72% 35%'
            : '36% 0%',
        }}
      >
        {/* <div
          style={{
            backgroundColor: '#fff',
            zindex: 1,
            // animation: '1.3s ease-out .3s 1 slideRighttoReveal',
            animationFillMode: 'forwards',
            width: '100%',
            height: '100%',
          }}
        /> */}
      </div>
    </div>
  );
};

export default HomeHero;
