import React, { Fragment } from 'react';
import {
  lightBlue,
  darkBlue,
  white,
  fontSize,
  flexBetween,
  flexText,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from './Button';
import {
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';
import {
  faPhone,
  faEnvelope,
  faLocationArrow,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import useBreakpoint from '../hooks/useBreakpoint';

export default function Footer() {
  const { breakpoint } = useBreakpoint();
  const mobile = breakpoint === 'sm' || breakpoint === 'xs';
  return (
    <div
      style={{
        backgroundColor: darkBlue,
        padding: mobile ? '20px' : '3em 5em',
        color: white,
      }}
    >
      <div
        style={{
          ...flexBetween,
          flexDirection: breakpoint === 'xs' && 'column',
          justifyContent: breakpoint === 'xs' && 'center',
        }}
      >
        <div
          style={{
            flex: !mobile && 1,
            alignSelf: breakpoint === 'xs' && 'center',
          }}
        >
          <img
            src="./images/CAECLogoLight.svg"
            alt="Calgary Animal Eyecare Logo"
            style={{ width: 200, display: mobile && 'none' }}
          />
          <div
            style={{
              color: lightBlue,
              display: mobile ? 'none' : 'flex',
              flex: 1,
              justifyContent: 'space-between',
              width: 150,
              marginLeft: breakpoint !== 'xs' && 35,
              marginRight: 'auto',
            }}
          >
            <FontAwesomeIcon
              icon={faInstagram}
              style={{ fontSize: fontSize.L }}
            />
            <FontAwesomeIcon
              icon={faLinkedinIn}
              style={{ fontSize: fontSize.L }}
            />
            <FontAwesomeIcon
              icon={faTwitter}
              style={{ fontSize: fontSize.L }}
            />
            <FontAwesomeIcon
              icon={faFacebookF}
              style={{ fontSize: fontSize.L }}
            />
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <h2>Book an Appointment</h2>
          <p
            style={{
              width: '22em',
              fontWeight: 300,
              marginLeft: breakpoint === 'xs' && 'auto',
              marginRight: breakpoint === 'xs' && 'auto',
            }}
          >
            Call today to speak with a member of our client care team and book a
            consultation.
          </p>
          <h2>587-943-4620</h2>
          {/* <Button text="587-943-4620" center={true} /> */}
        </div>
        <div style={{ color: white, flex: breakpoint !== 'xs' && 1 }}>
          <div
            style={{
              float: breakpoint !== 'xs' && 'right',
              textAlign: breakpoint === 'xs' && 'center',
            }}
          >
            <h2>Contact Us</h2>
            <div
              style={{
                ...flexText,
                fontWeight: 300,
                justifyContent: breakpoint === 'xs' && 'center',
              }}
            >
              <FontAwesomeIcon
                icon={faPhone}
                style={{ color: lightBlue, marginRight: 10 }}
              />
              <p
                style={{ fontSize: breakpoint === 'xs' && '0.8em', margin: 5 }}
              >
                587-943-4620
              </p>
            </div>
            <div
              style={{
                ...flexText,
                fontWeight: 300,
                justifyContent: breakpoint === 'xs' && 'center',
              }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ color: lightBlue, marginRight: 10 }}
              />
              <p
                style={{ fontSize: breakpoint === 'xs' && '0.8em', margin: 5 }}
              >
                info@calgaryanimaleyecare.ca
              </p>
            </div>
            <div
              style={{
                ...flexText,
                fontWeight: 300,
                justifyContent: breakpoint === 'xs' && 'center',
              }}
            >
              <FontAwesomeIcon
                icon={faLocationArrow}
                style={{ color: lightBlue, marginRight: 10 }}
              />
              <p
                style={{ fontSize: breakpoint === 'xs' && '0.8em', margin: 5 }}
              >
                11 Royal Vista Dr NW, Unit 2150 Calgary, AB
              </p>
            </div>
            <div
              style={{
                ...flexText,
                fontWeight: 300,
                justifyContent: breakpoint === 'xs' && 'center',
              }}
            >
              <FontAwesomeIcon
                icon={faClock}
                style={{ color: lightBlue, marginRight: 10 }}
              />
              <p
                style={{ fontSize: breakpoint === 'xs' && '0.8em', margin: 5 }}
              >
                Monday to Thursday 8am - 6:00pm <br /> Closed Friday, Saturday,
                Sunday
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr
        style={{
          backgroundColor: lightBlue,
          border: 'none',
          height: 1,
          marginTop: 20,
        }}
      />
      <div style={{ textAlign: 'center' }}>
        <p style={{ fontSize: breakpoint === 'xs' && '0.8em' }}>
          &copy; 2021 Calgary Animal Eye Care. All rigths reserved
        </p>
      </div>
    </div>
  );
}
