const breakpoints = {
  0: 'xs',
  600: 'sm',
  960: 'md',
  1280: 'lg',
  1500: 'xl',
  1920: 'xxl',
};

export default breakpoints;
