import React from 'react';

const Preview = ({ files }) => {
  return files.map((file, index) => {
    return file.type === 'application/pdf' ? (
      <div
        key={index}
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '10px 0 0 25px ',
        }}
      >
        <img
          style={{ maxWidth: '50px' }}
          src={`./images/pdfIcon.png`}
          alt={file.name}
        />
        <p style={{ marginLeft: 10 }}>{file.name}</p>
      </div>
    ) : (
      <div
        key={index}
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '10px 0 0 25px ',
        }}
      >
        <img
          style={{ maxWidth: '50px' }}
          src={`./uploads/${file.name}`}
          alt={file.name}
        />
        <p style={{ marginLeft: 10 }}>{file.name}</p>
      </div>
    );
  });
};

export default Preview;
