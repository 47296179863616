import React from 'react';
import ReferralForm from './ReferralForm';
import ReferralHero from './ReferralHero';

export default function Referral() {
  return (
    <div>
      <ReferralHero />
      <ReferralForm />
    </div>
  );
}
