import React, { useState } from 'react';
import { darkBlue, lightBlue, gray, error } from '../styles';
import Button from '../Button';
import { TextField } from '@mui/material';
import useBreakpoint from '../../hooks/useBreakpoint';
import useForm from '../../hooks/useForm';
import { validateContact } from '../../utils/validateInfo';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
  const { breakpoint } = useBreakpoint();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validateContact
  );
  const mobile = breakpoint === 'sm' || breakpoint === 'xs';

  const validation = (statusCode) => {
    const messages = {
      200: 'Message Sent',
      500: 'Something went wrong',
    };
    return messages[statusCode];
  };

  const sendMessage = async () => {
    const response = await axios.post('/api/email/submit', { values });
    const statusCode = response.request.status;
    if (statusCode === 200) {
      toast.success(validation(statusCode));
    } else {
      toast.warn(validation(statusCode));
    }
  };

  function submitForm() {
    setIsSubmitted(true);
    sendMessage();
  }

  return (
    <div
      style={{
        padding:
          breakpoint === 'xs'
            ? 20
            : breakpoint === 'md'
            ? '50px 35px 50px 50px'
            : '50px 20px 50px 40px',
      }}
    >
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div style={{ width: '47vw', marginBottom: 30 }}>
        {errors.name && <p style={error}>{errors.name}</p>}
        <div
          style={{
            display: 'flex',
            alignItems: mobile ? 'flex-start' : 'center',
            marginBottom: 15,
            flexDirection: mobile && 'column',
          }}
        >
          <h2
            style={{
              color: lightBlue,
              marginRight: mobile ? 10 : 72,
              fontSize: mobile && '1.3em',
            }}
          >
            Name
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.name}
            type="text"
            name="name"
            onChange={handleChange}
            variant="outlined"
            style={{ width: breakpoint === 'xs' ? 300 : 500 }}
            inputStyle={{ width: breakpoint === 'xs' ? 300 : 500 }}
          />
        </div>
        {errors.email && <p style={error}>{errors.email}</p>}
        <div
          style={{
            display: 'flex',
            alignItems: mobile ? 'flex-start' : 'center',
            marginBottom: 15,
            flexDirection: mobile && 'column',
          }}
        >
          <h2
            style={{
              color: lightBlue,
              marginRight: mobile ? 15 : 77,
              fontSize: mobile && '1.3em',
            }}
          >
            Email
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.email}
            type="text"
            name="email"
            onChange={handleChange}
            variant="outlined"
            style={{ width: breakpoint === 'xs' ? 300 : 500 }}
            inputStyle={{ width: breakpoint === 'xs' ? 300 : 500 }}
          />
        </div>
        {errors.message && <p style={error}>{errors.message}</p>}
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: mobile && 'column',
          }}
        >
          <h2
            style={{
              color: lightBlue,
              marginRight: 33,
              fontSize: mobile && '1.3em',
            }}
          >
            Message
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.message}
            type="text"
            name="message"
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={4}
            style={{ width: breakpoint === 'xs' ? 300 : 500 }}
            inputStyle={{ width: breakpoint === 'xs' ? 300 : 500 }}
          />
        </div>
      </div>
      <div style={{ marginLeft: mobile ? 0 : 142 }}>
        {!isSubmitted ? (
          <Button text="Submit" onClick={handleSubmit} />
        ) : (
          <Button text="Submitted" color={gray} />
        )}
      </div>
    </div>
  );
};

export default ContactForm;
