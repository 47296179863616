import React from 'react';
import { darkBlue } from '../styles';
import useBreakpoint from '../../hooks/useBreakpoint';

export default function WhatToExpect() {
  const { breakpoint } = useBreakpoint();
  const mobile = breakpoint === 'sm' || breakpoint === 'xs';
  return (
    <div
      style={{
        width: '100%',
        margin: breakpoint === 'xs' ? '90px 0' : '30px 0',
      }}
    >
      <h1
        style={{
          color: darkBlue,
          fontSize: breakpoint === 'xs' && '1.6em',
          marginLeft: 20,
        }}
      >
        What to expect at your appointment:
      </h1>
      <div
        style={{
          display: breakpoint !== 'xs' && 'flex',
          justifyContent: 'center',
        }}
      >
        <ol
          style={{
            width: breakpoint === 'xs' ? '85%' : '45%',
            paddingLeft: mobile && '35px',
          }}
        >
          <li>
            Once you check in with reception you will be greeted by an
            ophthalmology animal health technician (AHT) who will bring you back
            to the exam room.
          </li>
          <li>
            The technician will obtain a health history of your pet and perform
            a brief eye exam and perform diagnostic tests including Schirmer
            tear test, tonometry, and fluorescein staining.
          </li>
          <li>
            The technician will leave the room and present the case to the
            veterinary ophthalmologist. Both will return to the exam room to
            discuss your pet's presenting concern.
          </li>
          <li>
            The veterinarian will then perform a comprehensive eye exam on your
            pet, including slit lamp biomicroscopy and ophthalmoscopy. Your
            pet's diagnosis, treatment options, and further diagnostics, if
            necessary, will be discussed with you.
          </li>
        </ol>
        <ol
          start="5"
          style={{
            marginLeft: breakpoint !== 'xs' && '20px',
            width: breakpoint === 'xs' ? '85%' : '45%',
            paddingLeft: mobile && '35px',
          }}
        >
          <li>
            We will work with you to make decisions about the care of your
            animal and schedule or perform any diagnostics or treatments that
            are decided upon.
          </li>
          <li>
            Note: If you are traveling from a long distance, please bring this
            to our receptionists' attention so that we can best accommodate you
            and your pet if the need for surgery is suspected.
          </li>
          <li>
            Upon discharge, you will be provided with oral and written discharge
            instructions, medication information, home care instructions, and
            follow-up recommendations.
          </li>
        </ol>
      </div>
    </div>
  );
}
