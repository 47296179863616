import React from 'react';
import { white, darkBlueTransparent, flexBetween, darkBlue } from '../styles';
import useBreakpoint from '../../hooks/useBreakpoint';

export default function ServicesHero() {
  const { breakpoint } = useBreakpoint();
  const mobile = breakpoint === 'sm' || breakpoint === 'xs';

  return (
    <div
      style={{
        width: '100%',
        height: breakpoint !== 'xs' && '600px',
        position: 'relative',
        backgroundImage: !mobile && "url('./images/huskeyEye.jpg')",
        backgroundPosition: breakpoint !== 'xxl' ? '80% 58%' : '80% 50%',
        backgroundSize: '120%',
        display: !mobile && 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      }}
    >
      <div
        style={{
          backgroundColor: !mobile ? darkBlueTransparent : darkBlue,
          color: white,
          padding: breakpoint !== 'xs' ? '2em 5em' : 20,
        }}
      >
        <h1>Who is a veterinary ophthalmologist?</h1>
        <div style={flexBetween}>
          {breakpoint === 'xs' ? (
            <p>
              A veterinary ophthalmology specialist has graduated from a
              veterinary medicine college, completed a 1-year internship in
              medicine and surgery, and successfully completed a 3-year
              ACVO-approved residency in ophthalmology. For a person to become
              an ACVO Diplomate, their credentials are reviewed and must be
              accepted by the ACVO Credentials Committee for the applicant to be
              allowed to take the ACVO certifying examination ("Boards"). The
              board exam is a three section, four day process, consisting of
              written, practical, and surgical portions. Finally, after passing
              all of the above criteria, the veterinarian is recognized as a
              Diplomate of the American College of Veterinary Ophthalmologists®.
              They are now board- certified in veterinary ophthalmology. Only
              board-certified persons may call themselves a "veterinary
              specialist", a "specialist in ophthalmology", or an
              "ophthalmologist" in a veterinary setting.
            </p>
          ) : (
            <div>
              <p style={{ marginRight: '40px', fontSize: '15px' }}>
                A veterinary ophthalmology specialist has graduated from a
                veterinary medicine college, completed a 1-year internship in
                medicine and surgery, and successfully completed a 3-year
                ACVO-approved residency in ophthalmology. For a person to become
                an ACVO Diplomate, their credentials are reviewed and must be
                accepted by the ACVO Credentials Committee for the applicant to
                be allowed to take the ACVO certifying examination ("Boards").
              </p>
              <p style={{ fontSize: '15px' }}>
                The board exam is a three section, four day process, consisting
                of written, practical, and surgical portions. Finally, after
                passing all of the above criteria, the veterinarian is
                recognized as a Diplomate of the American College of Veterinary
                Ophthalmologists®. They are now board- certified in veterinary
                ophthalmology. Only board-certified persons may call themselves
                a "veterinary specialist", a "specialist in ophthalmology", or
                an "ophthalmologist" in a veterinary setting.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
