import React from 'react';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flexText, flexColumn, lightBlue } from '../styles';
import useBreakpoint from '../../hooks/useBreakpoint';

export default function ContactList() {
  const { breakpoint } = useBreakpoint();

  const mobile =
    breakpoint === 'sm' || breakpoint === 'xs' || breakpoint === 'md';

  const ContactfontStyle = {
    color: lightBlue,
    fontSize: mobile
      ? '1.3em'
      : breakpoint === 'md'
      ? '1.5em'
      : breakpoint === 'lg'
      ? '2em'
      : breakpoint === 'xl'
      ? '1.8em'
      : '2.5em',
  };
  return (
    <div
      style={{
        ...flexColumn,
        justifyContent: 'center',
        paddingBottom: mobile && 50,
        padding: breakpoint === 'xs' && 20,
        marginLeft: breakpoint === 'sm' && 100,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon
          icon={faPhone}
          style={{
            marginRight: 20,
            ...ContactfontStyle,
          }}
        />
        <h2
          style={{
            lineHeight: 0,
            fontWeight: 500,
            ...ContactfontStyle,
          }}
        >
          587-943-4620
        </h2>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon
          icon={faEnvelope}
          style={{
            marginRight: 20,
            ...ContactfontStyle,
          }}
        />
        <h2
          style={{
            fontWeight: 500,
            ...ContactfontStyle,
          }}
        >
          info@calgaryanimaleyecare.ca
        </h2>
      </div>
    </div>
  );
}
