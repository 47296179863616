import React, { useState } from 'react';
import { darkBlue, lightBlue, gray, error } from '../styles';
import { TextField } from '@mui/material';

const VetForm = ({
  values,
  handleChange,
  isSubmitted,
  breakpoint,
  errors,
  mobile,
}) => {
  return (
    <div
      style={{
        padding:
          breakpoint === 'xs'
            ? '10px 20px 0 20px'
            : breakpoint === 'md'
            ? '10px 35px 0 50px'
            : '10px 20px 0 40px',
      }}
    >
      <div style={{ width: '47vw', marginBottom: 30 }}>
        {errors.doctorName && <p style={error}>{errors.doctorName}</p>}
        <div
          style={{
            marginBottom: 15,
          }}
        >
          <h2
            style={{
              color: lightBlue,
              marginRight: mobile ? 10 : 72,
              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Referring Doctor's Name
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.doctorName}
            type="text"
            name="doctorName"
            onChange={handleChange}
            variant="outlined"
            style={{ width: breakpoint === 'xs' ? 300 : 500 }}
            inputStyle={{ width: breakpoint === 'xs' ? 300 : 500 }}
          />
        </div>
        {errors.email && <p style={error}>{errors.email}</p>}
        <div
          style={{
            marginBottom: 15,
          }}
        >
          <h2
            style={{
              color: lightBlue,
              marginRight: mobile ? 15 : 77,
              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Hospital Name
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.hospitalName}
            type="text"
            name="hospitalName"
            onChange={handleChange}
            variant="outlined"
            style={{ width: breakpoint === 'xs' ? 300 : 500 }}
            inputStyle={{ width: breakpoint === 'xs' ? 300 : 500 }}
          />
        </div>
        {errors.message && <p style={error}>{errors.message}</p>}
        <div
          style={{
            marginBottom: 15,
          }}
        >
          <h2
            style={{
              color: lightBlue,
              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Phone
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.vetPhone}
            type="text"
            name="vetPhone"
            onChange={handleChange}
            variant="outlined"
            style={{ width: breakpoint === 'xs' ? 300 : 500 }}
            inputStyle={{ width: breakpoint === 'xs' ? 300 : 500 }}
          />
        </div>
        {errors.message && <p style={error}>{errors.message}</p>}
        <div
          style={{
            marginBottom: 15,
          }}
        >
          <h2
            style={{
              color: lightBlue,
              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Fax
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.vetFax}
            type="text"
            name="vetFax"
            onChange={handleChange}
            variant="outlined"
            style={{ width: breakpoint === 'xs' ? 300 : 500 }}
            inputStyle={{ width: breakpoint === 'xs' ? 300 : 500 }}
          />
        </div>
        {errors.vetEmail && <p style={error}>{errors.vetEmail}</p>}
        <div
          style={{
            marginBottom: 15,
          }}
        >
          <h2
            style={{
              color: lightBlue,
              fontSize: '1.2em',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            Email Address
          </h2>
          <TextField
            disabled={isSubmitted}
            value={values.vetEmail}
            type="text"
            name="vetEmail"
            onChange={handleChange}
            variant="outlined"
            style={{ width: breakpoint === 'xs' ? 300 : 500 }}
            inputStyle={{ width: breakpoint === 'xs' ? 300 : 500 }}
          />
        </div>
      </div>
    </div>
  );
};

export default VetForm;
