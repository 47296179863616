import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import './style.css';

const FileUploader = ({ values, handleChange, isSubmitted }) => {
  return (
    <form method="post" action="#" id="#">
      <div className="form-group files">
        <label>Upload Your File </label>
        <input
          type="file"
          name="files"
          onChange={handleChange}
          className="form-control"
          multiple
        />
      </div>
    </form>
  );
};

export default FileUploader;
